<template>
  <v-app>
    <Header />
    <v-main class="main">
      <router-view />
    </v-main>
    <Footer />
    <BackTop />
  </v-app>
</template>

<script>
import { Header, Footer, BackTop } from '@/components';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    BackTop,
  },
};
</script>
<style lang="scss">
a {
  text-decoration: none;
}
.main {
  background: #f8f9fa;
}
</style>
