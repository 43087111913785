<template>
  <v-footer color="primary" padless>
    <v-row justify="center" no-gutters>
      <!-- <v-btn
        v-for="link in links"
        :key="link"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ link }}
      </v-btn> -->
      <v-col class="primary py-4 text-center white--text" cols="12">
        <strong>Powered by Yuhu</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
    links: ['关于我们', '联系我们'],
  }),
};
</script>