<template>
  <div v-ripple>
    <router-link :to="to">
      <slot></slot>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RippleLink',
  props: ['to'],
};
</script>
 