<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div class="ellipsis" :style="{ width: width }" v-bind="attrs" v-on="on">
        <slot></slot>
      </div>
    </template>
    <slot></slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Ellipsis',
  props: ['width'],
};
</script>

<style lang="scss" scoped>
.ellipsis {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
