<template>
  <v-fab-transition>
    <v-btn fab fixed bottom right v-show="isShow" @click="$vuetify.goTo(0)">
      <v-icon>mdi-arrow-up-bold</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'BackTop',
  data: () => ({
    isShow: false,
    pageYOffset: window.pageYOffset,
  }),
  mounted() {
    const that = this;
    window.onscroll = () => {
      that.isShow = window.pageYOffset > window.innerHeight;
    };
  },
};
</script>
