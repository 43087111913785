import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/Home.vue'),
  },
  {
    path: '/blocks',
    name: 'Blocks',
    component: () => import('../views/block/Blocks.vue'),
  },
  {
    path: '/blocks/:number',
    name: 'Block',
    component: () => import('../views/block/Block.vue'),
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('../views/transaction/Transactions.vue'),
  },
  {
    path: '/transactions/:hash',
    name: 'Transaction',
    component: () => import('../views/transaction/Transaction/index.vue'),
  },
  {
    path: '/address',
    name: 'Addresses',
    component: () => import('../views/address/Addresses.vue'),
  },
  {
    path: '/address/:hash',
    name: 'Address',
    component: () => import('../views/address/Address/index.vue'),
  },
  {
    path: '/address/:hash/:tokenId',
    name: 'Token',
    component: () => import('../views/address/Token/index.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
