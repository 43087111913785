/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Phoenix Project
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.2.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface DescribeEventDetailsRequestConditionEventDetail
 */
export interface DescribeEventDetailsRequestConditionEventDetail {
    /**
     * 
     * @type {string}
     * @memberof DescribeEventDetailsRequestConditionEventDetail
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DescribeEventDetailsRequestConditionEventDetail
     */
    details?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface DescribeTradingRecordsRequestConditionInputDetail
 */
export interface DescribeTradingRecordsRequestConditionInputDetail {
    /**
     * 
     * @type {string}
     * @memberof DescribeTradingRecordsRequestConditionInputDetail
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DescribeTradingRecordsRequestConditionInputDetail
     */
    details?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface GetTradingRecordChartResponseRecord
 */
export interface GetTradingRecordChartResponseRecord {
    /**
     * 
     * @type {string}
     * @memberof GetTradingRecordChartResponseRecord
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTradingRecordChartResponseRecord
     */
    count?: number;
}

/**
 * 
 * @export
 * @interface PhoenixAttribute
 */
export interface PhoenixAttribute {
    /**
     * 
     * @type {string}
     * @memberof PhoenixAttribute
     */
    attributeType?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixAttribute
     */
    attributeValue?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixAttribute
     */
    attributePercent?: string;
}

/**
 * 
 * @export
 * @interface PhoenixBlock
 */
export interface PhoenixBlock {
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    cursor?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    number?: string;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixBlock
     */
    time?: Date;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    size?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixBlock
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    parentHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    uncleHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    validator?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    coinbase?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    root?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    txHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    receiptHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    bloom?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    difficulty?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    gasLimit?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    gasUsed?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    extra?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    mixDigest?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixBlock
     */
    nonce?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixBlock
     */
    transactionCount?: number;
}

/**
 * 
 * @export
 * @interface PhoenixContract
 */
export interface PhoenixContract {
    /**
     * 
     * @type {string}
     * @memberof PhoenixContract
     */
    cursor?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixContract
     */
    contractType?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixContract
     */
    contractVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixContract
     */
    contractAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixContract
     */
    contractName?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixContract
     */
    contractSymbol?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixContract
     */
    contractDecimals?: number;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixContract
     */
    contractExpiredStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixContract
     */
    contractExpiredEndDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixContract
     */
    deployedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PhoenixContract
     */
    deployer?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixContract
     */
    deployedTxHash?: string;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeBlocksResponse
 */
export interface PhoenixDescribeBlocksResponse {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeBlocksResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeBlocksResponse
     */
    hasNextPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeBlocksResponse
     */
    endCursor?: string;
    /**
     * 
     * @type {Array<PhoenixBlock>}
     * @memberof PhoenixDescribeBlocksResponse
     */
    blocks?: Array<PhoenixBlock>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeContractsResponse
 */
export interface PhoenixDescribeContractsResponse {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeContractsResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeContractsResponse
     */
    hasNextPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeContractsResponse
     */
    endCursor?: string;
    /**
     * 
     * @type {Array<PhoenixContract>}
     * @memberof PhoenixDescribeContractsResponse
     */
    contracts?: Array<PhoenixContract>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeEventDetailsRequest
 */
export interface PhoenixDescribeEventDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeEventDetailsRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeEventDetailsRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeEventDetailsRequest
     */
    cursor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeEventDetailsRequest
     */
    reverse?: boolean;
    /**
     * 
     * @type {Array<PhoenixDescribeEventDetailsRequestCondition>}
     * @memberof PhoenixDescribeEventDetailsRequest
     */
    conditions?: Array<PhoenixDescribeEventDetailsRequestCondition>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeEventDetailsRequestCondition
 */
export interface PhoenixDescribeEventDetailsRequestCondition {
    /**
     * 
     * @type {Date}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    endTime?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    blockNumbers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    contractAddresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    walletAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    txHash?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    indexed?: number;
    /**
     * 
     * @type {Array<DescribeEventDetailsRequestConditionEventDetail>}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    eventDetails?: Array<DescribeEventDetailsRequestConditionEventDetail>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoenixDescribeEventDetailsRequestCondition
     */
    tokenIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeEventDetailsResponse
 */
export interface PhoenixDescribeEventDetailsResponse {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeEventDetailsResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeEventDetailsResponse
     */
    hasNextPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeEventDetailsResponse
     */
    endCursor?: string;
    /**
     * 
     * @type {Array<PhoenixEventDetail>}
     * @memberof PhoenixDescribeEventDetailsResponse
     */
    eventDetails?: Array<PhoenixEventDetail>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTokenHoldingInfoByContractRequest
 */
export interface PhoenixDescribeTokenHoldingInfoByContractRequest {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractRequest
     */
    cursor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractRequest
     */
    reverse?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractRequest
     */
    walletAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractRequest
     */
    contractAddress?: string;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTokenHoldingInfoByContractResponse
 */
export interface PhoenixDescribeTokenHoldingInfoByContractResponse {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponse
     */
    hasNextPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponse
     */
    endCursor?: string;
    /**
     * 
     * @type {PhoenixContract}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponse
     */
    contractInfo?: PhoenixContract;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponse
     */
    totalSupply?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponse
     */
    totalSupplyToDecimal?: string;
    /**
     * 
     * @type {Array<PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo>}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponse
     */
    holdingInfoes?: Array<PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo
 */
export interface PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo {
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo
     */
    cursor?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo
     */
    walletAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo
     */
    balance?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo
     */
    balanceToDecimal?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByContractResponseHoldingInfo
     */
    percentage?: string;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTokenHoldingInfoByWalletRequest
 */
export interface PhoenixDescribeTokenHoldingInfoByWalletRequest {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequest
     */
    cursor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequest
     */
    reverse?: boolean;
    /**
     * 
     * @type {Array<PhoenixDescribeTokenHoldingInfoByWalletRequestCondition>}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequest
     */
    conditions?: Array<PhoenixDescribeTokenHoldingInfoByWalletRequestCondition>;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequest
     */
    walletAddress?: string;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTokenHoldingInfoByWalletRequestCondition
 */
export interface PhoenixDescribeTokenHoldingInfoByWalletRequestCondition {
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequestCondition
     */
    contractAddressesIn?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequestCondition
     */
    contractAddressesNotIn?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequestCondition
     */
    contractTypes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletRequestCondition
     */
    isExpired?: boolean;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTokenHoldingInfoByWalletResponse
 */
export interface PhoenixDescribeTokenHoldingInfoByWalletResponse {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponse
     */
    hasNextPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponse
     */
    endCursor?: string;
    /**
     * 
     * @type {Array<PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo>}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponse
     */
    holdingInfoes?: Array<PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
 */
export interface PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo {
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    cursor?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    contractAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    contractType?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    contractVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    contractName?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    contractSymbol?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    contractDecimals?: number;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    contractExpiredStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    contractExpiredEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    balance?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTokenHoldingInfoByWalletResponseHoldingInfo
     */
    balanceToDecimal?: string;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTradingRecordsByTxHashesResponse
 */
export interface PhoenixDescribeTradingRecordsByTxHashesResponse {
    /**
     * 
     * @type {Array<PhoenixTradingRecord>}
     * @memberof PhoenixDescribeTradingRecordsByTxHashesResponse
     */
    tradingRecords?: Array<PhoenixTradingRecord>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTradingRecordsRequest
 */
export interface PhoenixDescribeTradingRecordsRequest {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTradingRecordsRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTradingRecordsRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTradingRecordsRequest
     */
    cursor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeTradingRecordsRequest
     */
    reverse?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTradingRecordsRequest
     */
    walletAddressForBill?: string;
    /**
     * 
     * @type {Array<PhoenixDescribeTradingRecordsRequestCondition>}
     * @memberof PhoenixDescribeTradingRecordsRequest
     */
    conditions?: Array<PhoenixDescribeTradingRecordsRequestCondition>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTradingRecordsRequestCondition
 */
export interface PhoenixDescribeTradingRecordsRequestCondition {
    /**
     * 
     * @type {Date}
     * @memberof PhoenixDescribeTradingRecordsRequestCondition
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixDescribeTradingRecordsRequestCondition
     */
    endTime?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoenixDescribeTradingRecordsRequestCondition
     */
    blockNumbers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoenixDescribeTradingRecordsRequestCondition
     */
    contractAddresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTradingRecordsRequestCondition
     */
    walletAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTradingRecordsRequestCondition
     */
    status?: string;
    /**
     * 
     * @type {Array<DescribeTradingRecordsRequestConditionInputDetail>}
     * @memberof PhoenixDescribeTradingRecordsRequestCondition
     */
    inputDetails?: Array<DescribeTradingRecordsRequestConditionInputDetail>;
}

/**
 * 
 * @export
 * @interface PhoenixDescribeTradingRecordsResponse
 */
export interface PhoenixDescribeTradingRecordsResponse {
    /**
     * 
     * @type {number}
     * @memberof PhoenixDescribeTradingRecordsResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixDescribeTradingRecordsResponse
     */
    hasNextPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixDescribeTradingRecordsResponse
     */
    endCursor?: string;
    /**
     * 
     * @type {Array<PhoenixTradingRecord>}
     * @memberof PhoenixDescribeTradingRecordsResponse
     */
    tradingRecords?: Array<PhoenixTradingRecord>;
}

/**
 * 
 * @export
 * @interface PhoenixEventDetail
 */
export interface PhoenixEventDetail {
    /**
     * 
     * @type {string}
     * @memberof PhoenixEventDetail
     */
    cursor?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixEventDetail
     */
    uniqueKey?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixEventDetail
     */
    txHash?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixEventDetail
     */
    indexed?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixEventDetail
     */
    blockHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixEventDetail
     */
    blockNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixEventDetail
     */
    pendedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PhoenixEventDetail
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PhoenixEventDetail
     */
    details?: { [key: string]: string; };
    /**
     * 
     * @type {PhoenixContract}
     * @memberof PhoenixEventDetail
     */
    contract?: PhoenixContract;
    /**
     * 
     * @type {PhoenixTradingRecord}
     * @memberof PhoenixEventDetail
     */
    tradingRecord?: PhoenixTradingRecord;
}

/**
 * 
 * @export
 * @interface PhoenixGetBalanceOfResponse
 */
export interface PhoenixGetBalanceOfResponse {
    /**
     * 
     * @type {string}
     * @memberof PhoenixGetBalanceOfResponse
     */
    balance?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixGetBalanceOfResponse
     */
    balanceToDecimal?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixGetBalanceOfResponse
     */
    balanceToDecimalWithSymbol?: string;
}

/**
 * 
 * @export
 * @interface PhoenixGetBlockResponse
 */
export interface PhoenixGetBlockResponse {
    /**
     * 
     * @type {PhoenixBlock}
     * @memberof PhoenixGetBlockResponse
     */
    block?: PhoenixBlock;
}

/**
 * 
 * @export
 * @interface PhoenixGetContractResponse
 */
export interface PhoenixGetContractResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixGetContractResponse
     */
    isContract?: boolean;
    /**
     * 
     * @type {PhoenixContract}
     * @memberof PhoenixGetContractResponse
     */
    contract?: PhoenixContract;
    /**
     * 
     * @type {string}
     * @memberof PhoenixGetContractResponse
     */
    totalSupply?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixGetContractResponse
     */
    totalSupplyToDecimal?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixGetContractResponse
     */
    paused?: boolean;
}

/**
 * 
 * @export
 * @interface PhoenixGetEventDetailResponse
 */
export interface PhoenixGetEventDetailResponse {
    /**
     * 
     * @type {PhoenixEventDetail}
     * @memberof PhoenixGetEventDetailResponse
     */
    eventDetail?: PhoenixEventDetail;
}

/**
 * 
 * @export
 * @interface PhoenixGetNFTTokenInfoResponse
 */
export interface PhoenixGetNFTTokenInfoResponse {
    /**
     * 
     * @type {PhoenixNFTToken}
     * @memberof PhoenixGetNFTTokenInfoResponse
     */
    nftToken?: PhoenixNFTToken;
}

/**
 * 
 * @export
 * @interface PhoenixGetTradingRecordChartResponse
 */
export interface PhoenixGetTradingRecordChartResponse {
    /**
     * 
     * @type {Array<GetTradingRecordChartResponseRecord>}
     * @memberof PhoenixGetTradingRecordChartResponse
     */
    records?: Array<GetTradingRecordChartResponseRecord>;
}

/**
 * 
 * @export
 * @interface PhoenixGetTradingRecordResponse
 */
export interface PhoenixGetTradingRecordResponse {
    /**
     * 
     * @type {PhoenixTradingRecord}
     * @memberof PhoenixGetTradingRecordResponse
     */
    tradingRecord?: PhoenixTradingRecord;
}

/**
 * 
 * @export
 * @interface PhoenixInputDetail
 */
export interface PhoenixInputDetail {
    /**
     * 
     * @type {string}
     * @memberof PhoenixInputDetail
     */
    txHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixInputDetail
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PhoenixInputDetail
     */
    details?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface PhoenixNFTToken
 */
export interface PhoenixNFTToken {
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    tokenId?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    contractType?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    tokenName?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    ownerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    contractAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    tokenDisplayUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    tokenDigest?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    artist?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    digitalName?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    publisherAddress?: string;
    /**
     * 
     * @type {Array<PhoenixAttribute>}
     * @memberof PhoenixNFTToken
     */
    tokenAttributes?: Array<PhoenixAttribute>;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    tokenDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixNFTToken
     */
    nftId?: string;
}

/**
 * 
 * @export
 * @interface PhoenixTradingRecord
 */
export interface PhoenixTradingRecord {
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    cursor?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    txHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    blockHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    blockNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof PhoenixTradingRecord
     */
    pendedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixTradingRecord
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixTradingRecord
     */
    nonce?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    gasPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    gasLimit?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    gas?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    gasToDecimal?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    valueToDecimal?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoenixTradingRecord
     */
    chainId?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoenixTradingRecord
     */
    size?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoenixTradingRecord
     */
    isOcTrading?: boolean;
    /**
     * 
     * @type {PhoenixInputDetail}
     * @memberof PhoenixTradingRecord
     */
    inputDetail?: PhoenixInputDetail;
    /**
     * 
     * @type {Array<PhoenixEventDetail>}
     * @memberof PhoenixTradingRecord
     */
    eventDetails?: Array<PhoenixEventDetail>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PhoenixTradingRecord
     */
    extraInfoes?: { [key: string]: string; };
    /**
     * 
     * @type {PhoenixContract}
     * @memberof PhoenixTradingRecord
     */
    contract?: PhoenixContract;
}


/**
 * AccountServiceApi - fetch parameter creator
 * @export
 */
export const AccountServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取某合约合约币持有详情
         * @param {string} contractAddress 必填：合约地址
         * @param {PhoenixDescribeTokenHoldingInfoByContractRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTokenHoldingInfoByContract(contractAddress: string, body: PhoenixDescribeTokenHoldingInfoByContractRequest, options: any = {}): FetchArgs {
            // verify required parameter 'contractAddress' is not null or undefined
            if (contractAddress === null || contractAddress === undefined) {
                throw new RequiredError('contractAddress','Required parameter contractAddress was null or undefined when calling describeTokenHoldingInfoByContract.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling describeTokenHoldingInfoByContract.');
            }
            const localVarPath = `/v1/account_infoes/contract/{contract_address}`
                .replace(`{${"contract_address"}}`, encodeURIComponent(String(contractAddress)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PhoenixDescribeTokenHoldingInfoByContractRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取某地址合约币持有详情
         * @param {string} walletAddress 必填：钱包地址
         * @param {PhoenixDescribeTokenHoldingInfoByWalletRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTokenHoldingInfoByWallet(walletAddress: string, body: PhoenixDescribeTokenHoldingInfoByWalletRequest, options: any = {}): FetchArgs {
            // verify required parameter 'walletAddress' is not null or undefined
            if (walletAddress === null || walletAddress === undefined) {
                throw new RequiredError('walletAddress','Required parameter walletAddress was null or undefined when calling describeTokenHoldingInfoByWallet.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling describeTokenHoldingInfoByWallet.');
            }
            const localVarPath = `/v1/account_infoes/wallet/{wallet_address}`
                .replace(`{${"wallet_address"}}`, encodeURIComponent(String(walletAddress)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PhoenixDescribeTokenHoldingInfoByWalletRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取余额
         * @param {string} walletAddress 必填：钱包地址
         * @param {string} [contractAddress] 选填：合约地址.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceOf(walletAddress: string, contractAddress?: string, options: any = {}): FetchArgs {
            // verify required parameter 'walletAddress' is not null or undefined
            if (walletAddress === null || walletAddress === undefined) {
                throw new RequiredError('walletAddress','Required parameter walletAddress was null or undefined when calling getBalanceOf.');
            }
            const localVarPath = `/v1/balanace_of/{wallet_address}`
                .replace(`{${"wallet_address"}}`, encodeURIComponent(String(walletAddress)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (contractAddress !== undefined) {
                localVarQueryParameter['contract_address'] = contractAddress;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountServiceApi - functional programming interface
 * @export
 */
export const AccountServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取某合约合约币持有详情
         * @param {string} contractAddress 必填：合约地址
         * @param {PhoenixDescribeTokenHoldingInfoByContractRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTokenHoldingInfoByContract(contractAddress: string, body: PhoenixDescribeTokenHoldingInfoByContractRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixDescribeTokenHoldingInfoByContractResponse> {
            const localVarFetchArgs = AccountServiceApiFetchParamCreator(configuration).describeTokenHoldingInfoByContract(contractAddress, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 获取某地址合约币持有详情
         * @param {string} walletAddress 必填：钱包地址
         * @param {PhoenixDescribeTokenHoldingInfoByWalletRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTokenHoldingInfoByWallet(walletAddress: string, body: PhoenixDescribeTokenHoldingInfoByWalletRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixDescribeTokenHoldingInfoByWalletResponse> {
            const localVarFetchArgs = AccountServiceApiFetchParamCreator(configuration).describeTokenHoldingInfoByWallet(walletAddress, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 获取余额
         * @param {string} walletAddress 必填：钱包地址
         * @param {string} [contractAddress] 选填：合约地址.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceOf(walletAddress: string, contractAddress?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixGetBalanceOfResponse> {
            const localVarFetchArgs = AccountServiceApiFetchParamCreator(configuration).getBalanceOf(walletAddress, contractAddress, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountServiceApi - factory interface
 * @export
 */
export const AccountServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary 获取某合约合约币持有详情
         * @param {string} contractAddress 必填：合约地址
         * @param {PhoenixDescribeTokenHoldingInfoByContractRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTokenHoldingInfoByContract(contractAddress: string, body: PhoenixDescribeTokenHoldingInfoByContractRequest, options?: any) {
            return AccountServiceApiFp(configuration).describeTokenHoldingInfoByContract(contractAddress, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 获取某地址合约币持有详情
         * @param {string} walletAddress 必填：钱包地址
         * @param {PhoenixDescribeTokenHoldingInfoByWalletRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTokenHoldingInfoByWallet(walletAddress: string, body: PhoenixDescribeTokenHoldingInfoByWalletRequest, options?: any) {
            return AccountServiceApiFp(configuration).describeTokenHoldingInfoByWallet(walletAddress, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 获取余额
         * @param {string} walletAddress 必填：钱包地址
         * @param {string} [contractAddress] 选填：合约地址.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceOf(walletAddress: string, contractAddress?: string, options?: any) {
            return AccountServiceApiFp(configuration).getBalanceOf(walletAddress, contractAddress, options)(fetch, basePath);
        },
    };
};

/**
 * AccountServiceApi - object-oriented interface
 * @export
 * @class AccountServiceApi
 * @extends {BaseAPI}
 */
export class AccountServiceApi extends BaseAPI {
    /**
     * 
     * @summary 获取某合约合约币持有详情
     * @param {string} contractAddress 必填：合约地址
     * @param {PhoenixDescribeTokenHoldingInfoByContractRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServiceApi
     */
    public describeTokenHoldingInfoByContract(contractAddress: string, body: PhoenixDescribeTokenHoldingInfoByContractRequest, options?: any) {
        return AccountServiceApiFp(this.configuration).describeTokenHoldingInfoByContract(contractAddress, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 获取某地址合约币持有详情
     * @param {string} walletAddress 必填：钱包地址
     * @param {PhoenixDescribeTokenHoldingInfoByWalletRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServiceApi
     */
    public describeTokenHoldingInfoByWallet(walletAddress: string, body: PhoenixDescribeTokenHoldingInfoByWalletRequest, options?: any) {
        return AccountServiceApiFp(this.configuration).describeTokenHoldingInfoByWallet(walletAddress, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 获取余额
     * @param {string} walletAddress 必填：钱包地址
     * @param {string} [contractAddress] 选填：合约地址.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServiceApi
     */
    public getBalanceOf(walletAddress: string, contractAddress?: string, options?: any) {
        return AccountServiceApiFp(this.configuration).getBalanceOf(walletAddress, contractAddress, options)(this.fetch, this.basePath);
    }

}

/**
 * BlockServiceApi - fetch parameter creator
 * @export
 */
export const BlockServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取区块列表
         * @param {number} [limit] 选填：limit(默认10，大于500则默认为500).
         * @param {number} [offset] 选填：offset(传此参数，出参才会返回totalCount).
         * @param {string} [cursor] 选填：cursor.
         * @param {boolean} [reverse] 选填：reverse(默认倒序).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeBlocks(limit?: number, offset?: number, cursor?: string, reverse?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/v1/blocks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (reverse !== undefined) {
                localVarQueryParameter['reverse'] = reverse;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取区块详情
         * @param {string} number 必填：块高度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlock(number: string, options: any = {}): FetchArgs {
            // verify required parameter 'number' is not null or undefined
            if (number === null || number === undefined) {
                throw new RequiredError('number','Required parameter number was null or undefined when calling getBlock.');
            }
            const localVarPath = `/v1/blocks/{number}`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlockServiceApi - functional programming interface
 * @export
 */
export const BlockServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取区块列表
         * @param {number} [limit] 选填：limit(默认10，大于500则默认为500).
         * @param {number} [offset] 选填：offset(传此参数，出参才会返回totalCount).
         * @param {string} [cursor] 选填：cursor.
         * @param {boolean} [reverse] 选填：reverse(默认倒序).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeBlocks(limit?: number, offset?: number, cursor?: string, reverse?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixDescribeBlocksResponse> {
            const localVarFetchArgs = BlockServiceApiFetchParamCreator(configuration).describeBlocks(limit, offset, cursor, reverse, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 获取区块详情
         * @param {string} number 必填：块高度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlock(number: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixGetBlockResponse> {
            const localVarFetchArgs = BlockServiceApiFetchParamCreator(configuration).getBlock(number, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BlockServiceApi - factory interface
 * @export
 */
export const BlockServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary 获取区块列表
         * @param {number} [limit] 选填：limit(默认10，大于500则默认为500).
         * @param {number} [offset] 选填：offset(传此参数，出参才会返回totalCount).
         * @param {string} [cursor] 选填：cursor.
         * @param {boolean} [reverse] 选填：reverse(默认倒序).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeBlocks(limit?: number, offset?: number, cursor?: string, reverse?: boolean, options?: any) {
            return BlockServiceApiFp(configuration).describeBlocks(limit, offset, cursor, reverse, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 获取区块详情
         * @param {string} number 必填：块高度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlock(number: string, options?: any) {
            return BlockServiceApiFp(configuration).getBlock(number, options)(fetch, basePath);
        },
    };
};

/**
 * BlockServiceApi - object-oriented interface
 * @export
 * @class BlockServiceApi
 * @extends {BaseAPI}
 */
export class BlockServiceApi extends BaseAPI {
    /**
     * 
     * @summary 获取区块列表
     * @param {number} [limit] 选填：limit(默认10，大于500则默认为500).
     * @param {number} [offset] 选填：offset(传此参数，出参才会返回totalCount).
     * @param {string} [cursor] 选填：cursor.
     * @param {boolean} [reverse] 选填：reverse(默认倒序).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockServiceApi
     */
    public describeBlocks(limit?: number, offset?: number, cursor?: string, reverse?: boolean, options?: any) {
        return BlockServiceApiFp(this.configuration).describeBlocks(limit, offset, cursor, reverse, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 获取区块详情
     * @param {string} number 必填：块高度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockServiceApi
     */
    public getBlock(number: string, options?: any) {
        return BlockServiceApiFp(this.configuration).getBlock(number, options)(this.fetch, this.basePath);
    }

}

/**
 * ContractServiceApi - fetch parameter creator
 * @export
 */
export const ContractServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取合约列表
         * @param {number} [limit] 选填：limit(默认10，大于500则默认为500).
         * @param {number} [offset] 选填：offset(传此参数，出参才会返回totalCount).
         * @param {string} [cursor] 选填：cursor.
         * @param {boolean} [reverse] 选填：reverse(默认倒序).
         * @param {Array<string>} [contractTypes] 选填：合约类型(\&quot;GRC20\&quot;, \&quot;COUPON\&quot;, \&quot;EXCHANGEMARKET20\&quot;, \&quot;EXCHANGEMARKET721\&quot;, \&quot;SALE721\&quot;, \&quot;COUPONVERIFICATION\&quot;, \&quot;CONPONREFUND\&quot;).
         * @param {string} [searchWord] 选填：模糊搜索(name, symbol).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeContracts(limit?: number, offset?: number, cursor?: string, reverse?: boolean, contractTypes?: Array<string>, searchWord?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/contracts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (reverse !== undefined) {
                localVarQueryParameter['reverse'] = reverse;
            }

            if (contractTypes) {
                localVarQueryParameter['contract_types'] = contractTypes;
            }

            if (searchWord !== undefined) {
                localVarQueryParameter['search_word'] = searchWord;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取合约
         * @param {string} contractAddress 必填：合约地址
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(contractAddress: string, options: any = {}): FetchArgs {
            // verify required parameter 'contractAddress' is not null or undefined
            if (contractAddress === null || contractAddress === undefined) {
                throw new RequiredError('contractAddress','Required parameter contractAddress was null or undefined when calling getContract.');
            }
            const localVarPath = `/v1/contracts/{contract_address}`
                .replace(`{${"contract_address"}}`, encodeURIComponent(String(contractAddress)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractServiceApi - functional programming interface
 * @export
 */
export const ContractServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取合约列表
         * @param {number} [limit] 选填：limit(默认10，大于500则默认为500).
         * @param {number} [offset] 选填：offset(传此参数，出参才会返回totalCount).
         * @param {string} [cursor] 选填：cursor.
         * @param {boolean} [reverse] 选填：reverse(默认倒序).
         * @param {Array<string>} [contractTypes] 选填：合约类型(\&quot;GRC20\&quot;, \&quot;COUPON\&quot;, \&quot;EXCHANGEMARKET20\&quot;, \&quot;EXCHANGEMARKET721\&quot;, \&quot;SALE721\&quot;, \&quot;COUPONVERIFICATION\&quot;, \&quot;CONPONREFUND\&quot;).
         * @param {string} [searchWord] 选填：模糊搜索(name, symbol).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeContracts(limit?: number, offset?: number, cursor?: string, reverse?: boolean, contractTypes?: Array<string>, searchWord?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixDescribeContractsResponse> {
            const localVarFetchArgs = ContractServiceApiFetchParamCreator(configuration).describeContracts(limit, offset, cursor, reverse, contractTypes, searchWord, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 获取合约
         * @param {string} contractAddress 必填：合约地址
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(contractAddress: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixGetContractResponse> {
            const localVarFetchArgs = ContractServiceApiFetchParamCreator(configuration).getContract(contractAddress, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContractServiceApi - factory interface
 * @export
 */
export const ContractServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary 获取合约列表
         * @param {number} [limit] 选填：limit(默认10，大于500则默认为500).
         * @param {number} [offset] 选填：offset(传此参数，出参才会返回totalCount).
         * @param {string} [cursor] 选填：cursor.
         * @param {boolean} [reverse] 选填：reverse(默认倒序).
         * @param {Array<string>} [contractTypes] 选填：合约类型(\&quot;GRC20\&quot;, \&quot;COUPON\&quot;, \&quot;EXCHANGEMARKET20\&quot;, \&quot;EXCHANGEMARKET721\&quot;, \&quot;SALE721\&quot;, \&quot;COUPONVERIFICATION\&quot;, \&quot;CONPONREFUND\&quot;).
         * @param {string} [searchWord] 选填：模糊搜索(name, symbol).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeContracts(limit?: number, offset?: number, cursor?: string, reverse?: boolean, contractTypes?: Array<string>, searchWord?: string, options?: any) {
            return ContractServiceApiFp(configuration).describeContracts(limit, offset, cursor, reverse, contractTypes, searchWord, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 获取合约
         * @param {string} contractAddress 必填：合约地址
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(contractAddress: string, options?: any) {
            return ContractServiceApiFp(configuration).getContract(contractAddress, options)(fetch, basePath);
        },
    };
};

/**
 * ContractServiceApi - object-oriented interface
 * @export
 * @class ContractServiceApi
 * @extends {BaseAPI}
 */
export class ContractServiceApi extends BaseAPI {
    /**
     * 
     * @summary 获取合约列表
     * @param {number} [limit] 选填：limit(默认10，大于500则默认为500).
     * @param {number} [offset] 选填：offset(传此参数，出参才会返回totalCount).
     * @param {string} [cursor] 选填：cursor.
     * @param {boolean} [reverse] 选填：reverse(默认倒序).
     * @param {Array<string>} [contractTypes] 选填：合约类型(\&quot;GRC20\&quot;, \&quot;COUPON\&quot;, \&quot;EXCHANGEMARKET20\&quot;, \&quot;EXCHANGEMARKET721\&quot;, \&quot;SALE721\&quot;, \&quot;COUPONVERIFICATION\&quot;, \&quot;CONPONREFUND\&quot;).
     * @param {string} [searchWord] 选填：模糊搜索(name, symbol).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractServiceApi
     */
    public describeContracts(limit?: number, offset?: number, cursor?: string, reverse?: boolean, contractTypes?: Array<string>, searchWord?: string, options?: any) {
        return ContractServiceApiFp(this.configuration).describeContracts(limit, offset, cursor, reverse, contractTypes, searchWord, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 获取合约
     * @param {string} contractAddress 必填：合约地址
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractServiceApi
     */
    public getContract(contractAddress: string, options?: any) {
        return ContractServiceApiFp(this.configuration).getContract(contractAddress, options)(this.fetch, this.basePath);
    }

}

/**
 * TokenServiceApi - fetch parameter creator
 * @export
 */
export const TokenServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取指定NFT Token信息
         * @param {string} contractAddress 必填：合约地址
         * @param {string} tokenId 必填：token id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNFTTokenInfo(contractAddress: string, tokenId: string, options: any = {}): FetchArgs {
            // verify required parameter 'contractAddress' is not null or undefined
            if (contractAddress === null || contractAddress === undefined) {
                throw new RequiredError('contractAddress','Required parameter contractAddress was null or undefined when calling getNFTTokenInfo.');
            }
            // verify required parameter 'tokenId' is not null or undefined
            if (tokenId === null || tokenId === undefined) {
                throw new RequiredError('tokenId','Required parameter tokenId was null or undefined when calling getNFTTokenInfo.');
            }
            const localVarPath = `/v1/nft/{contract_address}/{token_id}`
                .replace(`{${"contract_address"}}`, encodeURIComponent(String(contractAddress)))
                .replace(`{${"token_id"}}`, encodeURIComponent(String(tokenId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenServiceApi - functional programming interface
 * @export
 */
export const TokenServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取指定NFT Token信息
         * @param {string} contractAddress 必填：合约地址
         * @param {string} tokenId 必填：token id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNFTTokenInfo(contractAddress: string, tokenId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixGetNFTTokenInfoResponse> {
            const localVarFetchArgs = TokenServiceApiFetchParamCreator(configuration).getNFTTokenInfo(contractAddress, tokenId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TokenServiceApi - factory interface
 * @export
 */
export const TokenServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary 获取指定NFT Token信息
         * @param {string} contractAddress 必填：合约地址
         * @param {string} tokenId 必填：token id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNFTTokenInfo(contractAddress: string, tokenId: string, options?: any) {
            return TokenServiceApiFp(configuration).getNFTTokenInfo(contractAddress, tokenId, options)(fetch, basePath);
        },
    };
};

/**
 * TokenServiceApi - object-oriented interface
 * @export
 * @class TokenServiceApi
 * @extends {BaseAPI}
 */
export class TokenServiceApi extends BaseAPI {
    /**
     * 
     * @summary 获取指定NFT Token信息
     * @param {string} contractAddress 必填：合约地址
     * @param {string} tokenId 必填：token id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenServiceApi
     */
    public getNFTTokenInfo(contractAddress: string, tokenId: string, options?: any) {
        return TokenServiceApiFp(this.configuration).getNFTTokenInfo(contractAddress, tokenId, options)(this.fetch, this.basePath);
    }

}

/**
 * TransactionServiceApi - fetch parameter creator
 * @export
 */
export const TransactionServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 查询交易的event列表
         * @param {PhoenixDescribeEventDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeEventDetails(body: PhoenixDescribeEventDetailsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling describeEventDetails.');
            }
            const localVarPath = `/v1/event_details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PhoenixDescribeEventDetailsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询交易列表
         * @param {PhoenixDescribeTradingRecordsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTradingRecords(body: PhoenixDescribeTradingRecordsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling describeTradingRecords.');
            }
            const localVarPath = `/v1/trading_records`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PhoenixDescribeTradingRecordsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询交易列表(给定交易哈希)
         * @param {Array<string>} [txHashes] 必填：tx_hashes(length &lt;&#x3D; 1000).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTradingRecordsByTxHashes(txHashes?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/v1/trading_records:tx_hashes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (txHashes) {
                localVarQueryParameter['tx_hashes'] = txHashes;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询交易的event详情
         * @param {string} uniqueKey 必填：unique_key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetail(uniqueKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'uniqueKey' is not null or undefined
            if (uniqueKey === null || uniqueKey === undefined) {
                throw new RequiredError('uniqueKey','Required parameter uniqueKey was null or undefined when calling getEventDetail.');
            }
            const localVarPath = `/v1/event_details/{unique_key}`
                .replace(`{${"unique_key"}}`, encodeURIComponent(String(uniqueKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询交易详情
         * @param {string} txHash 必填：tx_hash
         * @param {string} [walletAddressForBill] 选填：用于收入支出参照的钱包地址.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingRecord(txHash: string, walletAddressForBill?: string, options: any = {}): FetchArgs {
            // verify required parameter 'txHash' is not null or undefined
            if (txHash === null || txHash === undefined) {
                throw new RequiredError('txHash','Required parameter txHash was null or undefined when calling getTradingRecord.');
            }
            const localVarPath = `/v1/trading_records/{tx_hash}`
                .replace(`{${"tx_hash"}}`, encodeURIComponent(String(txHash)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (walletAddressForBill !== undefined) {
                localVarQueryParameter['wallet_address_for_bill'] = walletAddressForBill;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取交易走势图
         * @param {string} [startDate] 必填：开始日期(格式:\&quot;20060102\&quot;，默认统计时区为东八区).
         * @param {string} [endDate] 必填：结束日期(格式:\&quot;20060102\&quot;，默认统计时区为东八区).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingRecordChart(startDate?: string, endDate?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/trading_record_chart`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionServiceApi - functional programming interface
 * @export
 */
export const TransactionServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 查询交易的event列表
         * @param {PhoenixDescribeEventDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeEventDetails(body: PhoenixDescribeEventDetailsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixDescribeEventDetailsResponse> {
            const localVarFetchArgs = TransactionServiceApiFetchParamCreator(configuration).describeEventDetails(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 查询交易列表
         * @param {PhoenixDescribeTradingRecordsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTradingRecords(body: PhoenixDescribeTradingRecordsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixDescribeTradingRecordsResponse> {
            const localVarFetchArgs = TransactionServiceApiFetchParamCreator(configuration).describeTradingRecords(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 查询交易列表(给定交易哈希)
         * @param {Array<string>} [txHashes] 必填：tx_hashes(length &lt;&#x3D; 1000).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTradingRecordsByTxHashes(txHashes?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixDescribeTradingRecordsByTxHashesResponse> {
            const localVarFetchArgs = TransactionServiceApiFetchParamCreator(configuration).describeTradingRecordsByTxHashes(txHashes, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 查询交易的event详情
         * @param {string} uniqueKey 必填：unique_key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetail(uniqueKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixGetEventDetailResponse> {
            const localVarFetchArgs = TransactionServiceApiFetchParamCreator(configuration).getEventDetail(uniqueKey, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 查询交易详情
         * @param {string} txHash 必填：tx_hash
         * @param {string} [walletAddressForBill] 选填：用于收入支出参照的钱包地址.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingRecord(txHash: string, walletAddressForBill?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixGetTradingRecordResponse> {
            const localVarFetchArgs = TransactionServiceApiFetchParamCreator(configuration).getTradingRecord(txHash, walletAddressForBill, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 获取交易走势图
         * @param {string} [startDate] 必填：开始日期(格式:\&quot;20060102\&quot;，默认统计时区为东八区).
         * @param {string} [endDate] 必填：结束日期(格式:\&quot;20060102\&quot;，默认统计时区为东八区).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingRecordChart(startDate?: string, endDate?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoenixGetTradingRecordChartResponse> {
            const localVarFetchArgs = TransactionServiceApiFetchParamCreator(configuration).getTradingRecordChart(startDate, endDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TransactionServiceApi - factory interface
 * @export
 */
export const TransactionServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary 查询交易的event列表
         * @param {PhoenixDescribeEventDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeEventDetails(body: PhoenixDescribeEventDetailsRequest, options?: any) {
            return TransactionServiceApiFp(configuration).describeEventDetails(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 查询交易列表
         * @param {PhoenixDescribeTradingRecordsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTradingRecords(body: PhoenixDescribeTradingRecordsRequest, options?: any) {
            return TransactionServiceApiFp(configuration).describeTradingRecords(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 查询交易列表(给定交易哈希)
         * @param {Array<string>} [txHashes] 必填：tx_hashes(length &lt;&#x3D; 1000).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        describeTradingRecordsByTxHashes(txHashes?: Array<string>, options?: any) {
            return TransactionServiceApiFp(configuration).describeTradingRecordsByTxHashes(txHashes, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 查询交易的event详情
         * @param {string} uniqueKey 必填：unique_key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetail(uniqueKey: string, options?: any) {
            return TransactionServiceApiFp(configuration).getEventDetail(uniqueKey, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 查询交易详情
         * @param {string} txHash 必填：tx_hash
         * @param {string} [walletAddressForBill] 选填：用于收入支出参照的钱包地址.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingRecord(txHash: string, walletAddressForBill?: string, options?: any) {
            return TransactionServiceApiFp(configuration).getTradingRecord(txHash, walletAddressForBill, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 获取交易走势图
         * @param {string} [startDate] 必填：开始日期(格式:\&quot;20060102\&quot;，默认统计时区为东八区).
         * @param {string} [endDate] 必填：结束日期(格式:\&quot;20060102\&quot;，默认统计时区为东八区).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingRecordChart(startDate?: string, endDate?: string, options?: any) {
            return TransactionServiceApiFp(configuration).getTradingRecordChart(startDate, endDate, options)(fetch, basePath);
        },
    };
};

/**
 * TransactionServiceApi - object-oriented interface
 * @export
 * @class TransactionServiceApi
 * @extends {BaseAPI}
 */
export class TransactionServiceApi extends BaseAPI {
    /**
     * 
     * @summary 查询交易的event列表
     * @param {PhoenixDescribeEventDetailsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public describeEventDetails(body: PhoenixDescribeEventDetailsRequest, options?: any) {
        return TransactionServiceApiFp(this.configuration).describeEventDetails(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 查询交易列表
     * @param {PhoenixDescribeTradingRecordsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public describeTradingRecords(body: PhoenixDescribeTradingRecordsRequest, options?: any) {
        return TransactionServiceApiFp(this.configuration).describeTradingRecords(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 查询交易列表(给定交易哈希)
     * @param {Array<string>} [txHashes] 必填：tx_hashes(length &lt;&#x3D; 1000).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public describeTradingRecordsByTxHashes(txHashes?: Array<string>, options?: any) {
        return TransactionServiceApiFp(this.configuration).describeTradingRecordsByTxHashes(txHashes, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 查询交易的event详情
     * @param {string} uniqueKey 必填：unique_key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public getEventDetail(uniqueKey: string, options?: any) {
        return TransactionServiceApiFp(this.configuration).getEventDetail(uniqueKey, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 查询交易详情
     * @param {string} txHash 必填：tx_hash
     * @param {string} [walletAddressForBill] 选填：用于收入支出参照的钱包地址.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public getTradingRecord(txHash: string, walletAddressForBill?: string, options?: any) {
        return TransactionServiceApiFp(this.configuration).getTradingRecord(txHash, walletAddressForBill, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 获取交易走势图
     * @param {string} [startDate] 必填：开始日期(格式:\&quot;20060102\&quot;，默认统计时区为东八区).
     * @param {string} [endDate] 必填：结束日期(格式:\&quot;20060102\&quot;，默认统计时区为东八区).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public getTradingRecordChart(startDate?: string, endDate?: string, options?: any) {
        return TransactionServiceApiFp(this.configuration).getTradingRecordChart(startDate, endDate, options)(this.fetch, this.basePath);
    }

}

