import Vue from 'vue';
import Vuex from 'vuex';
import { Transaction } from '@/api';

Vue.use(Vuex);

/**
 * state：定义变量
 * getter：store中的computed
 * actions：副作用，使用dispatch分发
 * mutations：更新状态，使用commit分发
 * modules：以模块拆分store
 */
export default new Vuex.Store({
  state: {
    transaction: {},
  },
  actions: {
    getTransaction({ commit, dispatch, state }, payload) {
      const { hash } = payload;
      Transaction.getTradingRecord(hash).then((res) => {
        commit('updateTransaction', res);
      });
    },
  },
  mutations: {
    updateTransaction(state, payload) {
      state.transaction = payload;
    },
  },
  modules: {},
});
