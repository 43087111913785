// swagger-client文件夹是由swagger生成的typescript-fetch-client https://editor.swagger.io/
// tslint对生成的文件夹有两条error，对此分别在tsconfig.json和tslint.json中做了如下修改：
// "strictPropertyInitialization": false, "no-reference": false
// 尚存在的问题：下载的客户端中使用了portable-fetch作为请求工具，但是会报错，故使用window.fetch

// 引入swagger-client中各模块的ServiceApi，在此初始化生成实例。在业务中引入实例，可调用其中的接口。
import {
  AccountServiceApi,
  BlockServiceApi,
  ContractServiceApi,
  TransactionServiceApi,
  TokenServiceApi,
} from '@/swagger-client';

const configuration = {};
const basePath = '/server';
const fetch = window.fetch;

const Account = new AccountServiceApi(configuration, basePath, fetch);
const Block = new BlockServiceApi(configuration, basePath, fetch);
const Contract = new ContractServiceApi(configuration, basePath, fetch);
const Transaction = new TransactionServiceApi(configuration, basePath, fetch);
const Token = new TokenServiceApi(configuration, basePath, fetch);
export { Account, Block, Contract, Transaction, Token };
