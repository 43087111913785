<template>
  <div>
    <v-app-bar app color="primary" dark>
      <router-link to="/">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          :src="`${CulturalTravelChainLogo}`"
          transition="scale-transition"
          width="175"
        />
      </router-link>
      <v-spacer />
      <v-btn
        text
        v-show="!isPhone"
        v-for="_ in menu"
        :key="_.title"
        :to="_.url"
      >
        {{ _.title }}
      </v-btn>
      <v-app-bar-nav-icon v-show="isPhone" @click="drawer = true" />
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list nav>
        <v-list-item-group>
          <v-list-item v-for="_ in menu" :key="_.title" :to="_.url">
            <v-list-item-icon>
              <v-icon>{{ _.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ _.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    isPhone: false,
    drawer: false,
    group: null,
    menu: [
      { title: '首页', icon: 'mdi-home', url: '/' },
      { title: '区块', icon: 'mdi-alpha-b-box', url: '/blocks' },
      { title: '交易', icon: 'mdi-alpha-t-box', url: '/transactions' },
      { title: '资产', icon: 'mdi-alpha-a-box', url: '/address' },
    ],
    CulturalTravelChainLogo: require('@/assets/CulturalTravelChain-logo.png'),
  }),
  mounted() {
    const that = this;
    window.onresize = () => {
      that.isPhone =
        document.body.clientWidth < that.$vuetify.breakpoint.thresholds.xs;
    };
    this.isPhone = this.$vuetify.breakpoint.xs;
  },
};
</script>

<style lang='scss'>
.title {
  color: #fff;
}
</style>
